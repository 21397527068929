import React from "react";
import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/about_img02.png";
import {Link} from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  The Next-Level <span>Crypto Trading Bot</span> on Telegram
                </h2>
              </div>
              <p>
                Experience the capabilities of PrecisionX, a comprehensive Telegram bot that seamlessly integrates advanced cryptocurrency trading functionalities into your communication platform. Through PrecisionX Bot, enhance your trading endeavors and foster well-informed decision-making, all within the confines of your Telegram conversation.
              </p>
              <Link to="https://t.me/PrecisionXBot" className="btn">
                Join Beta
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
