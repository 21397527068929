import React, {useEffect, useState} from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import icon01 from "../../assets/img/icon/choose_icon01.svg";
import icon02 from "../../assets/img/icon/choose_icon02.svg";
import icon03 from "../../assets/img/icon/choose_icon03.svg";
import icon04 from "../../assets/img/icon/choose_icon04.svg";
import WhyChooseUsItem from "./WhyChooseUsItem";

const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Seamless Buy",
      description: "Effortlessly initiate contract functions through PrecisionX Bot. This platform facilitates seamless execution of precise actions such as targeted token acquisitions or transactions. Whether your objective is immediate purchases, strategic front-running, or back-running of trader transactions, PrecisionX Bot provides a comprehensive solution to meet your needs.",
    },
    {
      src: icon02,
      alt: "",
      link: "/",
      title: "Complete Privacy",
      description: "Enjoy the peace of mind that comes with truly private transactions. Our platform utilizes the latest blockchain technology to ensure that your trading activities remain completely anonymous and untraceable.",
    },
    {
      src: icon03,
      alt: "",
      link: "/",
      title: <>FriendTech Share Bot</>,
      description: "Introducing the latest innovation in trading technology - the FriendTech Exchange feature. Now, you can seamlessly buy and sell shares of the Friend.tech platform, all within the comfort of our user-friendly platform.",
    },
    {
      src: icon04,
      alt: "",
      link: "/",
      title: "Honeypot/Rug protection",
      description: "Get an instant warning if about to purchase a honeypot, also the ability to frontrun the dev if he’s removing liquidity",
    },
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Token Holder Rewards",
      description: "Participate in a unique ecosystem where a portion of transaction fees is redistributed as ETH to holders. This innovative model provides an additional source of potential earnings and incentivizes long-term engagement.",
    },
    {
      src: icon04,
      alt: "",
      link: "/",
      title: "Real-Time Updates",
      description: "Stay informed with real-time updates and notifications on market movements, executed trades, and your portfolio's performance, enabling you to make informed decisions.",
    },
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section className="choose-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">why Choose us </span>
              <h2 className="title">
                Why choose <span>PrecisionX Bot</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
          <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>

        <div className="slide-progressbar">
          <div
            style={{
              // width: `${count * toMultiply}%`,
              backgroundSize: `${count * toMultiply}% 100%`,
            }}
            className="slide-filler"
          />
        </div>

        {/* <div
          className="slide-progress"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span className="slider__label sr-only"></span>
        </div> */}
      </div>
    </section>
  );
};

export default WhyChooseUs;
