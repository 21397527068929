import React from "react";
import {Link} from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import {scrollToTop} from "../../lib/helpers";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-5 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="PrecisionX Logo" style={{
                      width: "300px"
                    }} />
                  </Link>
                  <div className="footer-content">
                    <p>
                      Unlock the potential of PrecisionX Bot – your gateway to a seamless integration of cutting-edge cryptocurrency trading features within your Telegram communication platform. Elevate your trading pursuits and cultivate informed decision-making, all encapsulated within the context of your Telegram interactions.
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a href="https://twitter.com/PrecisionXBot">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/precisionx_portal">
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. All Rights Reserved PrecisionX Bot</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="https://t.me/precisionx_portal">Telegram</Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/PrecisionXBot">Twitter</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
